import Vue from 'vue';
import Component from 'vue-class-component';
import Highcharts from 'highcharts';
import HighchartsRegression from 'highcharts-regression';
import { Watch } from 'vue-property-decorator';
import cloneDeep from 'lodash/cloneDeep';

HighchartsRegression(Highcharts);

let chart: Highcharts.Chart;

@Component({
  name: 'AppAreaPriceChangeChart',

  props: {
    points: Array,
  },
})
export default class AreaPriceChangeChart extends Vue {
  // props
  points: any[];

  // data()

  // computed

  @Watch('points')
  pointsWatch(): void {
    if (chart) {
      this.destroyChart();
      this.$nextTick(() => {
        this.initChart();
      });
    }
  }

  // lifecycle hooks
  mounted() {
    this.initChart();
  }

  beforeDestroy() {
    this.destroyChart();
  }

  // methods
  destroyChart() {
    if (chart) {
      chart.destroy();
      chart = null;
    }
  }

  initChart() {
    chart = Highcharts.chart(this.$refs.chart as any, {
      // credits: false as any,

      // title: false as any,

      yAxis: {
        title: {
          text: 'Цена за кв. м.',
        },
        labels: {
          formatter(): string {
            return `${(this as any).value / 1000} т. р.`;
          },
        },
      } as any,

      xAxis: {
        title: {
          text: 'Год',
        },
        type: 'datetime',
        dateTimeLabelFormats: {
          month: '%Y',
          year: '%Y',
        } as any,
      },

      legend: {
        layout: 'vertical',
        align: 'right',
        verticalAlign: 'middle',
      },

      plotOptions: {
        series: {
          label: {
            connectorAllowed: false,
          },
          pointStart: 2010,
        },
      },
      tooltip: {
        enabled: false,
      },
      series: [
        {
          // regression: true,
          // regressionSettings: {
          //   name: 'Линия тренда',
          //   type: 'polynomial',
          //   color: 'tomato',
          //   dashStyle: 'solid',
          //   hideInLegend: true,
          // },
          type: 'scatter',
          name: 'Test',
          color: 'dodgerblue',
          data: cloneDeep(this.points),
          showInLegend: false,
        },
      ],

      responsive: {
        rules: [
          {
            condition: {
              maxWidth: 500,
            },
            chartOptions: {
              legend: {
                layout: 'horizontal',
                align: 'center',
                verticalAlign: 'bottom',
              },
            },
          },
        ],
      },
    });
  }
}
