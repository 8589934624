var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "chart" }, [
    _c("h6", { staticClass: "text-center" }, [
      _vm._v("\n    Изменения цен за кв. м.\n  ")
    ]),
    _c("div", { ref: "chart" })
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }