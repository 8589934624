import Axios from 'axios';
import { environment } from 'shared/environment';

async function geocoder(requestParams: GeocoderRequestParams): Promise<any> {
  const apiUrl = 'https://geocode-maps.yandex.ru/1.x/';
  const params: GeocoderRequestParams = {
    apikey: environment.yandexMapApiKey,
    format: 'json',
    ...requestParams,
  };

  return Axios.get(apiUrl, { params }).then((response) => response.data);
}

function organizations() {
  // empty
}

const yaMapApi = {
  geocoder,
  organizations,
};

export default yaMapApi;
