import Vue from 'vue';
import Component from 'vue-class-component';
import _ from 'lodash';
import pick from 'lodash/pick';
import groupBy from 'lodash/groupBy';
import last from 'lodash/last';
import orderBy from 'lodash/orderBy';
import keyBy from 'lodash/keyBy';
import omit from 'lodash/omit';
import { Point } from 'chart.js';

import { Validators } from '@/core/utils/validator';
import { calcDistanceByCoords } from '@/core/utils/calcDistanceByCoordinates';
import yaMapsApi from 'shared/api/yandex-map';
import { AuthTypes } from 'shared/store/modules/auth/types';

import AppPropertyImage from '@/views/ObjectDetail/components/PropertyImage.vue';
import AppAreaPriceChangeChart from '@/views/Estimate/components/AreaPriceChangeChart/AreaPriceChangeChart.vue';
import propertyConstants from '@/core/utils/constants/property';
import LocStorage from '@/core/utils/localStorage';

// let response: EstimateResponse;
let samples: Sample[];

const DEMO_MODEL: EstimateModel = {
  address: 'Респ Татарстан, г Набережные Челны, Набережночелнинский пр-кт, д 49',
  area: '84',
  rooms: 3,
  level: 5,
  levels: 10,
  built_at: 2013,
  object_type: 1,
  property_object_type_id: 1,
  latitude: null,
  longitude: null,
  apartment_condition: 3,
};

const DEFAULT_MODEL: EstimateModel = {
  area: null,
  level: null,
  levels: null,
  rooms: null,
  address: null,
  object_type: null,
  latitude: null,
  longitude: null,
  built_at: null,
  property_object_type_id: null,
  apartment_condition: 3,
};

@Component({
  name: 'AppEstimate',

  components: { AppPropertyImage, AppAreaPriceChangeChart },

  filters: {
    formatMeter: (value: number) => {
      return value >= 1000 ? `${(value / 1000).toFixed(2)} км` : `${value} м`;
    },

    toPercent: (value: number) => {
      return (value * 100).toFixed(1);
    },
  },
})
export default class Estimate extends Vue {
  // props
  info: RealtyObject;

  // data()
  loading: boolean = false;
  cost: number = null;
  buildingTypes: ByKey<PropertyBuildingType> = keyBy(propertyConstants.BUILDING_TYPES, 'id');
  objectTypes: ByKey<PropertyObjectType> = keyBy(propertyConstants.OBJECT_TYPES, 'id');
  stateOfApartment: ByKey<StateOfApartmentItem> = keyBy(
    propertyConstants.STATE_OF_APARTMENTS,
    'id',
  );
  response: any = null;
  samplesLimit: number = 10;
  samples: Sample[] = [];
  showInfo: SimpleObject<boolean> = {};
  excludeSamples: boolean = false;
  model: EstimateModel = { ...DEFAULT_MODEL };
  advancedInfo: SimpleObject = {};
  priceRangeEdit: boolean = false;
  priceAverageEdit: boolean = false;
  chartPoints: Point[] = [];

  // computed
  get isAdmin(): boolean {
    return this.$store.getters[AuthTypes.getters.IS_ADMIN];
  }

  get sampleObjects(): any[] {
    if (this.response) {
      return this.samples.slice(0, this.samplesLimit);
    }

    return [];
  }

  // lifecycle
  async created() {
    const estimateStorage = LocStorage.getItem('estimate', {});
    const orderItemID: string = this.$route.query.order_item_id as string;

    if (orderItemID) {
      if (orderItemID in estimateStorage) {
        const estimateData: EstimateUserModel = JSON.parse(estimateStorage[orderItemID]);
        const parsedData = pick(estimateData, Object.keys(DEFAULT_MODEL)) as EstimateModel;
        if (parsedData) {
          this.model = {
            ...this.model,
            ...parsedData,
          };

          this.advancedInfo = pick(estimateData, 'state_of_apartment');
          LocStorage.setItem('estimate', omit(estimateStorage, orderItemID));
        }
      } else {
        const [ order ] = await this.$api.admin.Orders<AdminOrderInfo[]>({ orderItemID });

        if (order && order.OrderItem.length) {
          const { comment } = order.OrderItem[0];
          if (typeof comment === 'string') {
            const model = JSON.parse(comment);
            this.model = {
              ...this.model,
              ...model,
            };
          }
        }
      }

      if (this.$route.query.calc) {
        this.onSubmit();
      }
    }
  }

  // methods

  async onSubmit() {
    this.loading = true;
    this.showInfo = {};

    try {
      const data = await yaMapsApi.geocoder({ geocode: this.model.address });

      const featureMember = data.response.GeoObjectCollection.featureMember;
      if (featureMember && featureMember.length) {
        const coords = featureMember[0].GeoObject.Point.pos.split(' ');
        [ this.model.longitude, this.model.latitude ] = _.map(coords, parseFloat);
      }
    } catch (error) {
      console.error(error);
      this.loading = false;
      return;
    }

    let body: EstimateRequestBody = { sample: { ...this.model } };

    if (!body.sample.built_at) {
      body = omit(body, 'sample.built_at') as EstimateRequestBody;
    }

    if (this.excludeSamples) {
      if (this.response) {
        samples.forEach((item) => {
          const foundSample = this.samples.find((s: Sample) => {
            return s.ads_id === item.ads_id && s.upload_at === item.upload_at;
          });

          if (foundSample) {
            item.use_in_estimate = foundSample.use_in_estimate;
          }
        });

        body.samples = samples;
      }
    }

    try {
      const r = await this.$api.estimate.Calc(body);

      samples = r.samples;
      const filteredSamples = [];
      const samplesGroup = groupBy(r.samples, 'ads_id');

      for (const id in samplesGroup) {
        const items: Sample[] = samplesGroup[id];
        let item: Sample;

        if (items.length > 1) {
          const priceChanges: PriceChangeItem[] = [];

          const sortedSamples: Sample[] = orderBy(
            items,
            (i) => new Date(i.upload_at).getTime(),
            'asc',
          );

          for (let index = 0, len = sortedSamples.length; index < len; index++) {
            const sample = sortedSamples[index];
            const prevSample = sortedSamples[index - 1];
            const priceChangeItem: PriceChangeItem = {
              date: sample.upload_at,
              price: parseInt(sample.price),
              difference: 0,
            };

            if (prevSample) {
              priceChangeItem.difference = parseInt(sample.price) - parseInt(prevSample.price);
            }

            priceChanges.push(priceChangeItem);
          }

          item = last(sortedSamples);

          if (!priceChanges.every((i) => i.difference === 0)) {
            item.PriceChanges = priceChanges;
          }
        } else {
          item = items[0];
        }

        item.distance = calcDistanceByCoords(
          [ this.model.latitude as any, this.model.longitude as any ],
          [ parseFloat(item.latitude as any), parseFloat(item.longitude as any) ],
        );

        filteredSamples.push(item);
      }

      const chartPoints: Point[] = filteredSamples.map<Point>((i: Sample) => {
        const squareMeterPrice = Math.round(parseInt(i.price) / parseInt(i.area as string));
        return {
          x: new Date(i.upload_at).getTime(),
          y: squareMeterPrice,
        };
      });

      this.chartPoints = orderBy(chartPoints, 'x', 'desc');

      const currentObjectArea = parseFloat(this.model.area as any);

      this.samples = orderBy(
        filteredSamples,
        [ 'distance', (i) => Math.abs(currentObjectArea - parseFloat(i.area as string)) ],
        [ 'asc', 'asc' ],
      );

      r.sample_size = this.samples.length;
      this.response = pick(r, 'cost', 'sample', 'sample_size', 'pricing');
    } catch (error) {
      console.error(error);
      const text = this.$api.getErrorMessage(error);
      this.$noty.error({ text });
    }

    this.loading = false;
  }

  showMoreSamples() {
    this.samplesLimit += 10;
  }

  togglePropertySelection(obj: PropertyBase) {
    obj.use_in_estimate = !obj.use_in_estimate;
    this.excludeSamples = true;
  }

  toggleInfo(objectId: string) {
    if (objectId in this.showInfo) {
      this.showInfo[objectId] = !this.showInfo[objectId];
    } else {
      this.$set(this.showInfo, objectId, true);
    }
  }

  squareMeterPrice(obj: Sample): number {
    const price = parseInt(obj.price);
    const area = parseFloat(obj.area as string);
    if (price > 0 && area > 0) {
      return price / area;
    }

    return 1;
  }

  print() {
    window.print();
  }

  clearForm() {
    this.model = { ...DEFAULT_MODEL };
    this.excludeSamples = false;
  }

  fillDemo() {
    this.model = { ...this.model, ...DEMO_MODEL };
  }

  selectText(event: Event) {
    const target = event.currentTarget as HTMLInputElement;
    target.select();
  }

  validationRules(): ValidationConfig {
    return {
      area: [ Validators.required ],
      level: [ Validators.required ],
      levels: [ Validators.required ],
      rooms: [ Validators.required ],
      address: [ Validators.required ],
      object_type: [ Validators.required ],
      property_object_type_id: [ Validators.required ],
    };
  }
}
