var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "container estimate-container" }, [
    _c("div", { staticClass: "page-wrapper " }, [
      _c(
        "div",
        {
          staticClass:
            "estimate-wrapper estimate-form wrapper-padding_sm d-print-none border-bottom"
        },
        [
          _c("h4", { staticClass: "mb-3" }, [
            _vm._v("\n        Расчет стоимости квартиры "),
            _c(
              "span",
              { staticClass: "btn-link small", on: { click: _vm.fillDemo } },
              [_vm._v("demo")]
            )
          ]),
          _c(
            "app-form",
            {
              attrs: { rules: _vm.validationRules, model: _vm.model },
              on: { submit: _vm.onSubmit }
            },
            [
              _c(
                "app-form-group",
                {
                  staticClass: "estimate-form__field estimate-form__field_full",
                  attrs: {
                    "model-name": "address",
                    label: "Адрес",
                    required: ""
                  }
                },
                [
                  _c("app-dadata", {
                    attrs: { size: "default" },
                    model: {
                      value: _vm.model.address,
                      callback: function($$v) {
                        _vm.$set(_vm.model, "address", $$v)
                      },
                      expression: "model.address"
                    }
                  })
                ],
                1
              ),
              _c("div", { staticClass: "estimate-form__row row" }, [
                _c(
                  "div",
                  { staticClass: "col" },
                  [
                    _c(
                      "app-form-group",
                      {
                        staticClass: "estimate-form__field",
                        attrs: {
                          "model-name": "area",
                          label: "Площадь",
                          tooltip: "",
                          required: ""
                        }
                      },
                      [
                        _c(
                          "app-input",
                          {
                            model: {
                              value: _vm.model.area,
                              callback: function($$v) {
                                _vm.$set(_vm.model, "area", _vm._n($$v))
                              },
                              expression: "model.area"
                            }
                          },
                          [
                            _c("small", { staticClass: "text-muted" }, [
                              _vm._v("м"),
                              _c("sup", [_vm._v("2")])
                            ])
                          ]
                        )
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "col" },
                  [
                    _c(
                      "app-form-group",
                      {
                        staticClass: "estimate-form__field",
                        attrs: {
                          "model-name": "rooms",
                          label: "Кол-во комнат",
                          tooltip: "",
                          required: ""
                        }
                      },
                      [
                        _c("app-input", {
                          model: {
                            value: _vm.model.rooms,
                            callback: function($$v) {
                              _vm.$set(_vm.model, "rooms", _vm._n($$v))
                            },
                            expression: "model.rooms"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "col" },
                  [
                    _c(
                      "app-form-group",
                      {
                        staticClass: "estimate-form__field",
                        attrs: {
                          "model-name": "level",
                          label: "Этаж",
                          tooltip: "",
                          required: ""
                        }
                      },
                      [
                        _c("app-input", {
                          model: {
                            value: _vm.model.level,
                            callback: function($$v) {
                              _vm.$set(_vm.model, "level", _vm._n($$v))
                            },
                            expression: "model.level"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "col" },
                  [
                    _c(
                      "app-form-group",
                      {
                        staticClass: "estimate-form__field",
                        attrs: {
                          "model-name": "levels",
                          label: "Этажность",
                          tooltip: "",
                          required: ""
                        }
                      },
                      [
                        _c("app-input", {
                          model: {
                            value: _vm.model.levels,
                            callback: function($$v) {
                              _vm.$set(_vm.model, "levels", _vm._n($$v))
                            },
                            expression: "model.levels"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "col" },
                  [
                    _c(
                      "app-form-group",
                      { attrs: { label: "Год постройки" } },
                      [
                        _c("app-input", {
                          model: {
                            value: _vm.model.built_at,
                            callback: function($$v) {
                              _vm.$set(_vm.model, "built_at", _vm._n($$v))
                            },
                            expression: "model.built_at"
                          }
                        })
                      ],
                      1
                    )
                  ],
                  1
                )
              ]),
              _c("div", { staticClass: "estimate-form__row row" }, [
                _c(
                  "div",
                  { staticClass: "col" },
                  [
                    _c(
                      "app-form-group",
                      {
                        staticClass: "estimate-form__field",
                        attrs: {
                          "model-name": "object_type",
                          label: "Тип дома",
                          tooltip: "",
                          required: ""
                        }
                      },
                      [
                        _c(
                          "select",
                          {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.model.object_type,
                                expression: "model.object_type"
                              }
                            ],
                            staticClass: "custom-select form-control",
                            on: {
                              change: function($event) {
                                var $$selectedVal = Array.prototype.filter
                                  .call($event.target.options, function(o) {
                                    return o.selected
                                  })
                                  .map(function(o) {
                                    var val = "_value" in o ? o._value : o.value
                                    return val
                                  })
                                _vm.$set(
                                  _vm.model,
                                  "object_type",
                                  $event.target.multiple
                                    ? $$selectedVal
                                    : $$selectedVal[0]
                                )
                              }
                            }
                          },
                          [
                            _c("option", { domProps: { value: null } }, [
                              _vm._v(
                                "\n                  Не выбрано\n                "
                              )
                            ]),
                            _vm._l(_vm.buildingTypes, function(buildType) {
                              return _c(
                                "option",
                                {
                                  key: buildType.id,
                                  domProps: { value: buildType.id }
                                },
                                [
                                  _vm._v(
                                    "\n                  " +
                                      _vm._s(buildType.name) +
                                      "\n                "
                                  )
                                ]
                              )
                            })
                          ],
                          2
                        )
                      ]
                    )
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "col" },
                  [
                    _c(
                      "app-form-group",
                      {
                        attrs: {
                          label: "Вид объекта",
                          "model-name": "property_object_type_id",
                          tooltip: "",
                          required: ""
                        }
                      },
                      [
                        _c(
                          "select",
                          {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.model.property_object_type_id,
                                expression: "model.property_object_type_id"
                              }
                            ],
                            staticClass: "custom-select",
                            on: {
                              change: function($event) {
                                var $$selectedVal = Array.prototype.filter
                                  .call($event.target.options, function(o) {
                                    return o.selected
                                  })
                                  .map(function(o) {
                                    var val = "_value" in o ? o._value : o.value
                                    return val
                                  })
                                _vm.$set(
                                  _vm.model,
                                  "property_object_type_id",
                                  $event.target.multiple
                                    ? $$selectedVal
                                    : $$selectedVal[0]
                                )
                              }
                            }
                          },
                          [
                            _c("option", { domProps: { value: null } }, [
                              _vm._v(
                                "\n                  Не выбрано\n                "
                              )
                            ]),
                            _vm._l(_vm.objectTypes, function(objectType) {
                              return _c(
                                "option",
                                {
                                  key: objectType.id,
                                  domProps: { value: objectType.id }
                                },
                                [
                                  _vm._v(
                                    "\n                  " +
                                      _vm._s(objectType.name) +
                                      "\n                "
                                  )
                                ]
                              )
                            })
                          ],
                          2
                        )
                      ]
                    )
                  ],
                  1
                ),
                _c(
                  "div",
                  { staticClass: "col estimate-form__buttons" },
                  [
                    _c(
                      "app-button",
                      {
                        staticClass: "estimate-form__submit",
                        attrs: {
                          type: "submit",
                          loading: _vm.loading,
                          block: ""
                        }
                      },
                      [_vm._v("\n              Расчитать\n            ")]
                    ),
                    _c(
                      "app-button",
                      {
                        staticClass: "estimate-form__eraser",
                        attrs: { variant: "light" },
                        on: { click: _vm.clearForm }
                      },
                      [_c("i", { staticClass: "fas fa-eraser" })]
                    )
                  ],
                  1
                )
              ]),
              _vm.advancedInfo.state_of_apartment
                ? _c("div", { staticClass: "mb-3" }, [
                    _c("div", { staticClass: "small text-muted" }, [
                      _vm._v("\n            Дополнительно\n          ")
                    ]),
                    _c("div", [
                      _vm._v("\n            Состояние кваритры:\n            "),
                      _vm.stateOfApartment[_vm.advancedInfo.state_of_apartment]
                        ? _c("b", { staticClass: "font-weight-500" }, [
                            _vm._v(
                              "\n              " +
                                _vm._s(
                                  _vm.stateOfApartment[
                                    _vm.advancedInfo.state_of_apartment
                                  ].name
                                ) +
                                "\n            "
                            )
                          ])
                        : _vm._e()
                    ])
                  ])
                : _vm._e(),
              _c(
                "div",
                {
                  staticClass:
                    "d-sm-flex justify-content-between align-items-center"
                },
                [
                  _c("div", { staticClass: "small" }, [
                    _c("span", { staticClass: "text-danger" }, [_vm._v("*")]),
                    _vm._v(
                      "\n            - Обязательные поля для заполнения\n          "
                    )
                  ]),
                  _c(
                    "app-checkbox",
                    {
                      model: {
                        value: _vm.excludeSamples,
                        callback: function($$v) {
                          _vm.excludeSamples = $$v
                        },
                        expression: "excludeSamples"
                      }
                    },
                    [
                      _vm._v(
                        "\n            Исключать не выделенные объекты\n          "
                      )
                    ]
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "div",
        {
          staticClass:
            "estimate-wrapper wrapper-padding_sm estimate-object-info border-bottom d-none d-print-block"
        },
        [
          _c("h4", { staticClass: "mb-1" }, [
            _vm._v("\n        Отчет о стоимости квартиры\n      ")
          ]),
          _c("h6", { staticClass: "mb-4 text-muted font-weight-normal" }, [
            _vm._v(
              "\n        По адресу - " + _vm._s(_vm.model.address) + "\n      "
            )
          ]),
          _c("div", { staticClass: "estimate-object" }, [
            _c("div", { staticClass: "estimate-object-item" }, [
              _c("div", { staticClass: "estimate-object-item__label" }, [
                _vm._v("\n            Площадь\n          ")
              ]),
              _c("div", { staticClass: "estimate-object-item__dots" }),
              _c("div", { staticClass: "estimate-object-item__value" }, [
                _vm._v("\n            " + _vm._s(_vm.model.area) + " м"),
                _c("sup", [_vm._v("2")])
              ])
            ]),
            _c("div", { staticClass: "estimate-object-item" }, [
              _c("div", { staticClass: "estimate-object-item__label" }, [
                _vm._v("\n            Количество комнат\n          ")
              ]),
              _c("div", { staticClass: "estimate-object-item__dots" }),
              _c("div", { staticClass: "estimate-object-item__value" }, [
                _vm._v(
                  "\n            " + _vm._s(_vm.model.rooms) + "\n          "
                )
              ])
            ]),
            _c("div", { staticClass: "estimate-object-item" }, [
              _c("div", { staticClass: "estimate-object-item__label" }, [
                _vm._v("\n            Этаж\n          ")
              ]),
              _c("div", { staticClass: "estimate-object-item__dots" }),
              _c("div", { staticClass: "estimate-object-item__value" }, [
                _vm._v(
                  "\n            " +
                    _vm._s(_vm.model.level) +
                    " из " +
                    _vm._s(_vm.model.levels) +
                    "\n          "
                )
              ])
            ]),
            _vm.model.property_object_type_id
              ? _c("div", { staticClass: "estimate-object-item" }, [
                  _c("div", { staticClass: "estimate-object-item__label" }, [
                    _vm._v("\n            Вид объекта\n          ")
                  ]),
                  _c("div", { staticClass: "estimate-object-item__dots" }),
                  _vm.objectTypes[_vm.model.property_object_type_id]
                    ? _c(
                        "div",
                        { staticClass: "estimate-object-item__value" },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(
                                _vm.objectTypes[
                                  _vm.model.property_object_type_id
                                ].name
                              ) +
                              "\n          "
                          )
                        ]
                      )
                    : _vm._e()
                ])
              : _vm._e(),
            _vm.model.object_type
              ? _c("div", { staticClass: "estimate-object-item" }, [
                  _c("div", { staticClass: "estimate-object-item__label" }, [
                    _vm._v("\n            Тип дома\n          ")
                  ]),
                  _c("div", { staticClass: "estimate-object-item__dots" }),
                  _vm.buildingTypes[_vm.model.object_type]
                    ? _c(
                        "div",
                        { staticClass: "estimate-object-item__value" },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(
                                _vm.buildingTypes[_vm.model.object_type].name
                              ) +
                              "\n          "
                          )
                        ]
                      )
                    : _vm._e()
                ])
              : _vm._e(),
            _vm.model.built_at
              ? _c("div", { staticClass: "estimate-object-item" }, [
                  _c("div", { staticClass: "estimate-object-item__label" }, [
                    _vm._v("\n            Год постройки\n          ")
                  ]),
                  _c("div", { staticClass: "estimate-object-item__dots" }),
                  _c("div", { staticClass: "estimate-object-item__value" }, [
                    _vm._v(
                      "\n            " +
                        _vm._s(_vm.model.built_at) +
                        "\n          "
                    )
                  ])
                ])
              : _vm._e()
          ])
        ]
      ),
      _vm.response
        ? _c(
            "div",
            {
              staticClass: "estimate-wrapper estimate-result wrapper-padding_sm"
            },
            [
              _c("div", { staticClass: "estimate-result-price mb-4" }, [
                _c("div", { staticClass: "estimate-result-price__average" }, [
                  _c("h6", [
                    _vm._v(
                      "\n            Средняя оценочная стоимость квартиры\n            "
                    ),
                    _c(
                      "span",
                      {
                        staticClass: "btn-link d-print-none",
                        on: { click: _vm.print }
                      },
                      [_vm._v("pdf")]
                    )
                  ]),
                  _c("div", { staticClass: "estimate-price-average" }, [
                    _c(
                      "h4",
                      { staticClass: "mb-0" },
                      [
                        _vm._v(
                          "\n              " +
                            _vm._s(_vm._f("currency")(_vm.response.cost)) +
                            "\n              "
                        ),
                        _c("app-input", {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: _vm.priceAverageEdit,
                              expression: "priceAverageEdit"
                            }
                          ],
                          staticClass: "estimate-price-input",
                          model: {
                            value: _vm.response.cost,
                            callback: function($$v) {
                              _vm.$set(_vm.response, "cost", $$v)
                            },
                            expression: "response.cost"
                          }
                        })
                      ],
                      1
                    ),
                    _c(
                      "span",
                      {
                        staticClass: "ml-2 d-print-none",
                        on: {
                          click: function($event) {
                            _vm.priceAverageEdit = !_vm.priceAverageEdit
                          }
                        }
                      },
                      [_c("i", { staticClass: "fas fa-edit fa-fw" })]
                    )
                  ])
                ]),
                _c(
                  "div",
                  {
                    staticClass:
                      "estimate-result-price__range estimate-price-range"
                  },
                  [
                    _c("h6", [_vm._v("Диапазон цены")]),
                    _c("div", { staticClass: "estimate-price-range__info" }, [
                      _c(
                        "span",
                        {
                          staticClass: "mr-2 d-print-none",
                          on: {
                            click: function($event) {
                              _vm.priceRangeEdit = !_vm.priceRangeEdit
                            }
                          }
                        },
                        [_c("i", { staticClass: "fas fa-edit fa-fw" })]
                      ),
                      _c(
                        "span",
                        { staticClass: "estimate-price-range__price" },
                        [
                          _vm._v(
                            "\n              " +
                              _vm._s(
                                _vm._f("currency")(
                                  _vm.response.pricing.price_min
                                )
                              ) +
                              "\n              "
                          ),
                          _c("app-input", {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.priceRangeEdit,
                                expression: "priceRangeEdit"
                              }
                            ],
                            staticClass: "estimate-price-input d-print-none",
                            attrs: { size: "sm" },
                            model: {
                              value: _vm.response.pricing.price_min,
                              callback: function($$v) {
                                _vm.$set(_vm.response.pricing, "price_min", $$v)
                              },
                              expression: "response.pricing.price_min"
                            }
                          })
                        ],
                        1
                      ),
                      _c("span", { staticClass: "mx-1" }, [_vm._v(" — ")]),
                      _c(
                        "span",
                        { staticClass: "estimate-price-range__price" },
                        [
                          _vm._v(
                            "\n              " +
                              _vm._s(
                                _vm._f("currency")(
                                  _vm.response.pricing.price_max
                                )
                              ) +
                              "\n              "
                          ),
                          _c("app-input", {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.priceRangeEdit,
                                expression: "priceRangeEdit"
                              }
                            ],
                            staticClass: "estimate-price-input d-print-none",
                            attrs: { size: "sm" },
                            model: {
                              value: _vm.response.pricing.price_max,
                              callback: function($$v) {
                                _vm.$set(_vm.response.pricing, "price_max", $$v)
                              },
                              expression: "response.pricing.price_max"
                            }
                          })
                        ],
                        1
                      )
                    ])
                  ]
                )
              ]),
              _c("AppAreaPriceChangeChart", {
                attrs: { points: _vm.chartPoints }
              }),
              _c("h6", { staticClass: "text-center mb-3" }, [
                _vm._v(
                  "\n        Объекты на основе которых был проведен расчет\n      "
                )
              ]),
              _c(
                "table",
                {
                  staticClass:
                    "table table-sm table-borderless estimate-result__table"
                },
                [
                  _vm._m(0),
                  _vm._l(_vm.sampleObjects, function(obj, index) {
                    return _c(
                      "tbody",
                      {
                        key: index,
                        class: { "d-print-none": !obj.use_in_estimate }
                      },
                      [
                        _c(
                          "tr",
                          {
                            on: {
                              click: function($event) {
                                return _vm.toggleInfo(obj.property_info_id)
                              }
                            }
                          },
                          [
                            _c(
                              "td",
                              {
                                staticClass: "estimate-result__img-cell",
                                attrs: { rowspan: "2" }
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "estimate-result__img-wrap" },
                                  [
                                    _c("app-checkbox", {
                                      staticClass: "d-print-none",
                                      attrs: { model: obj.use_in_estimate },
                                      on: {
                                        change: function($event) {
                                          return _vm.togglePropertySelection(
                                            obj
                                          )
                                        }
                                      }
                                    }),
                                    _c("app-property-image", {
                                      attrs: { photos: obj.photos }
                                    })
                                  ],
                                  1
                                )
                              ]
                            ),
                            _c(
                              "td",
                              { staticClass: "estimate-result__rooms" },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(obj.rooms) +
                                    " - комнатная\n            "
                                )
                              ]
                            ),
                            _c("td", { staticClass: "estimate-result__area" }, [
                              _vm._v(
                                "\n              " + _vm._s(obj.area) + " м"
                              ),
                              _c("sup", [_vm._v("2")])
                            ]),
                            _c("td", { staticClass: "estimate-result__area" }, [
                              _vm._v(
                                "\n              " +
                                  _vm._s(
                                    _vm._f("toPercent")(obj.coef_similarity)
                                  ) +
                                  "%\n            "
                              )
                            ]),
                            _c(
                              "td",
                              { staticClass: "estimate-result__level" },
                              [
                                _vm._v("\n              " + _vm._s(obj.level)),
                                _c("span", { staticClass: "text-muted" }, [
                                  _vm._v(" из " + _vm._s(obj.levels))
                                ])
                              ]
                            ),
                            _c(
                              "td",
                              { staticClass: "estimate-result__distance" },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(
                                      _vm._f("formatMeter")(obj.distance)
                                    ) +
                                    "\n            "
                                )
                              ]
                            ),
                            _c(
                              "td",
                              { staticClass: "estimate-result__price" },
                              [
                                _c(
                                  "span",
                                  {
                                    directives: [
                                      {
                                        name: "tippy",
                                        rawName: "v-tippy",
                                        value: { placement: "top" },
                                        expression: "{ placement: 'top' }"
                                      }
                                    ]
                                  },
                                  [
                                    _c("i", {
                                      staticClass: "fas fa-chart-line mr-2",
                                      class: obj.PriceChanges
                                        ? "text-success"
                                        : "text-muted"
                                    }),
                                    _vm._v(
                                      "\n\n                " +
                                        _vm._s(_vm._f("currency")(obj.price)) +
                                        "\n\n                "
                                    ),
                                    obj.PriceChanges
                                      ? [
                                          _c(
                                            "div",
                                            {
                                              staticClass: "price-changes",
                                              attrs: {
                                                "data-tippy-content": "hide"
                                              }
                                            },
                                            [
                                              _c("h6", [
                                                _vm._v("Изменение цены:")
                                              ]),
                                              _vm._l(obj.PriceChanges, function(
                                                item,
                                                i
                                              ) {
                                                return _c(
                                                  "div",
                                                  {
                                                    key: i,
                                                    staticClass:
                                                      "price-changes-item"
                                                  },
                                                  [
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "price-changes-item__date"
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                        " +
                                                            _vm._s(
                                                              _vm._f("date")(
                                                                item.date,
                                                                "DD.MM.YYYY"
                                                              )
                                                            ) +
                                                            "\n                      "
                                                        )
                                                      ]
                                                    ),
                                                    _c(
                                                      "span",
                                                      {
                                                        staticClass:
                                                          "price-changes-item__price"
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            _vm._f("currency")(
                                                              item.price
                                                            )
                                                          )
                                                        )
                                                      ]
                                                    ),
                                                    item.difference !== 0
                                                      ? _c(
                                                          "span",
                                                          {
                                                            staticClass:
                                                              "price-changes-item__difference",
                                                            class:
                                                              item.difference <
                                                              0
                                                                ? "text-success"
                                                                : "text-danger"
                                                          },
                                                          [
                                                            _vm._v(
                                                              "\n                        " +
                                                                _vm._s(
                                                                  item.difference >
                                                                    0
                                                                    ? "+"
                                                                    : ""
                                                                ) +
                                                                _vm._s(
                                                                  _vm._f(
                                                                    "currency"
                                                                  )(
                                                                    item.difference
                                                                  )
                                                                ) +
                                                                "\n                      "
                                                            )
                                                          ]
                                                        )
                                                      : _vm._e()
                                                  ]
                                                )
                                              })
                                            ],
                                            2
                                          )
                                        ]
                                      : [
                                          _c(
                                            "div",
                                            {
                                              attrs: {
                                                "data-tippy-content": "hide"
                                              }
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm._f("date")(
                                                    obj.upload_at,
                                                    "DD.MM.YYYY"
                                                  )
                                                )
                                              )
                                            ]
                                          )
                                        ]
                                  ],
                                  2
                                )
                              ]
                            ),
                            _vm._m(1, true)
                          ]
                        ),
                        _c(
                          "tr",
                          {
                            on: {
                              click: function($event) {
                                return _vm.toggleInfo(obj.property_info_id)
                              }
                            }
                          },
                          [
                            _c(
                              "td",
                              {
                                staticClass: "estimate-result__address",
                                attrs: { colspan: "8" }
                              },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(obj.address) +
                                    "\n            "
                                )
                              ]
                            )
                          ]
                        ),
                        _c(
                          "tr",
                          {
                            staticClass: "d-print-table-row",
                            class: {
                              "d-none": !_vm.showInfo[obj.property_info_id]
                            }
                          },
                          [
                            _c("td"),
                            _c(
                              "td",
                              {
                                staticClass: "estimate-result__adv-col",
                                attrs: { colspan: "3" }
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "estimate-adv-info" },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "estimate-adv-info-item" },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "estimate-adv-info-item__label"
                                          },
                                          [
                                            _vm._v(
                                              "\n                    Тип дома\n                  "
                                            )
                                          ]
                                        ),
                                        _c("div", {
                                          staticClass:
                                            "estimate-adv-info-item__dot"
                                        }),
                                        _vm.buildingTypes[obj.object_type]
                                          ? _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "estimate-adv-info-item__value"
                                              },
                                              [
                                                _vm._v(
                                                  "\n                    " +
                                                    _vm._s(
                                                      _vm.buildingTypes[
                                                        obj.object_type
                                                      ].name
                                                    ) +
                                                    "\n                  "
                                                )
                                              ]
                                            )
                                          : _vm._e()
                                      ]
                                    ),
                                    obj.built_at
                                      ? _c(
                                          "div",
                                          {
                                            staticClass:
                                              "estimate-adv-info-item"
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "estimate-adv-info-item__label"
                                              },
                                              [
                                                _vm._v(
                                                  "\n                    Год постройки\n                  "
                                                )
                                              ]
                                            ),
                                            _c("div", {
                                              staticClass:
                                                "estimate-adv-info-item__dot"
                                            }),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "estimate-adv-info-item__value"
                                              },
                                              [
                                                _c("input", {
                                                  directives: [
                                                    {
                                                      name: "model",
                                                      rawName: "v-model.number",
                                                      value: obj.built_at,
                                                      expression:
                                                        "obj.built_at",
                                                      modifiers: {
                                                        number: true
                                                      }
                                                    }
                                                  ],
                                                  staticClass:
                                                    "form-control form-control-sm estimate-adv-info-item__built-at",
                                                  attrs: { type: "text" },
                                                  domProps: {
                                                    value: obj.built_at
                                                  },
                                                  on: {
                                                    focus: _vm.selectText,
                                                    input: function($event) {
                                                      if (
                                                        $event.target.composing
                                                      ) {
                                                        return
                                                      }
                                                      _vm.$set(
                                                        obj,
                                                        "built_at",
                                                        _vm._n(
                                                          $event.target.value
                                                        )
                                                      )
                                                    },
                                                    blur: function($event) {
                                                      return _vm.$forceUpdate()
                                                    }
                                                  }
                                                })
                                              ]
                                            )
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm.objectTypes[obj.property_object_type_id]
                                      ? _c(
                                          "div",
                                          {
                                            staticClass:
                                              "estimate-adv-info-item"
                                          },
                                          [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "estimate-adv-info-item__label"
                                              },
                                              [
                                                _vm._v(
                                                  "\n                    Вид объекта\n                  "
                                                )
                                              ]
                                            ),
                                            _c("div", {
                                              staticClass:
                                                "estimate-adv-info-item__dot"
                                            }),
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "estimate-adv-info-item__value"
                                              },
                                              [
                                                _vm._v(
                                                  "\n                    " +
                                                    _vm._s(
                                                      _vm.objectTypes[
                                                        obj
                                                          .property_object_type_id
                                                      ].name
                                                    ) +
                                                    "\n                  "
                                                )
                                              ]
                                            )
                                          ]
                                        )
                                      : _vm._e()
                                  ]
                                )
                              ]
                            ),
                            _c(
                              "td",
                              {
                                staticClass: "estimate-result__adv-col",
                                attrs: { colspan: "3" }
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "estimate-adv-info" },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "estimate-adv-info-item" },
                                      [
                                        _vm._m(2, true),
                                        _c("div", {
                                          staticClass:
                                            "estimate-adv-info-item__dot"
                                        }),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "estimate-adv-info-item__value"
                                          },
                                          [
                                            _vm._v(
                                              "\n                    " +
                                                _vm._s(
                                                  _vm._f("currency")(
                                                    _vm.squareMeterPrice(obj)
                                                  )
                                                ) +
                                                "\n                  "
                                            )
                                          ]
                                        )
                                      ]
                                    ),
                                    _c(
                                      "div",
                                      { staticClass: "estimate-adv-info-item" },
                                      [
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "estimate-adv-info-item__label"
                                          },
                                          [
                                            _vm._v(
                                              "\n                    Цена при " +
                                                _vm._s(_vm.model.area) +
                                                "м"
                                            ),
                                            _c("sup", [_vm._v("2")])
                                          ]
                                        ),
                                        _c("div", {
                                          staticClass:
                                            "estimate-adv-info-item__dot"
                                        }),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "estimate-adv-info-item__value"
                                          },
                                          [
                                            _vm._v(
                                              "\n                    " +
                                                _vm._s(
                                                  _vm._f("currency")(
                                                    _vm.model.area *
                                                      _vm.squareMeterPrice(obj)
                                                  )
                                                ) +
                                                "\n                  "
                                            )
                                          ]
                                        )
                                      ]
                                    )
                                  ]
                                )
                              ]
                            ),
                            _c("td")
                          ]
                        )
                      ]
                    )
                  })
                ],
                2
              ),
              _vm.samplesLimit < _vm.response.sample_size
                ? _c(
                    "div",
                    {
                      staticClass:
                        "estimate-result__more text-center pb-3 d-print-none",
                      on: { click: _vm.showMoreSamples }
                    },
                    [
                      _c("span", { staticClass: "btn-link" }, [
                        _vm._v("Показать еще")
                      ])
                    ]
                  )
                : _vm._e()
            ],
            1
          )
        : _vm._e()
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("thead", [
      _c("tr", [
        _c("th"),
        _c("th", [_vm._v("Квартира")]),
        _c("th", [_vm._v("Площадь")]),
        _c("th", [_vm._v("Схожесть")]),
        _c("th", [_vm._v("Этаж")]),
        _c("th", [_vm._v("Расстояние")]),
        _c("th", { staticClass: "text-right" }, [
          _vm._v("\n              Цена\n            ")
        ]),
        _c("th")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("td", { staticClass: "estimate-result__arrow" }, [
      _c("i", { staticClass: "fas fa-chevron-down d-print-none" })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "estimate-adv-info-item__label" }, [
      _vm._v("\n                    Цена за м"),
      _c("sup", [_vm._v("2")])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }